import { createContext, useReducer } from "react"
import axios from "../../utils/axios"
import GameReducers from "./GameReducers"
// import AlertContext from "../Alert/AlertContext"
// import { toast } from "react-toastify"

const GameContext = createContext()

export const GamesProvider = ({ children }) => {
  const initialState = {
    loading: false,
    liveSessionsLoading: false,
    singleGameLoading: false,
    allGames: [],
    liveSessions: [],
    singleGameData: {},
    allActivities: [],
    gameStats: {}
  }

  // eslint-disable-next-line
  const [state, dispatch] = useReducer(GameReducers, initialState)

  // const { setSuccessAlert, setErrorAlert } = useContext(AlertContext)

    // Method to Set Loading to False
    const setLoadingToFalse = (payload) => {
      dispatch({ type: "CLEAR_LOADING", payload: payload ? payload : null });
    };
  

  // Fetch All Adventures
  const fetchAllGames = async () => {
  
    try {
      dispatch({
        type: "SET_LOADING",
      })
  
      const response = await axios.get("/admin/game");
  
      const data = response?.data?.data?.games

      // console.log("this is ther",response?.data)
  
      dispatch({
        type: "SET_ALL_GAMES",
        payload: data,
      })

      dispatch({
        type: "SET_GAME_STATS",
        payload: response?.data?.data,
      })

      
      
  
      setLoadingToFalse()
    } catch (error) {
      console.log({error})
      setLoadingToFalse();
    }
  }

  const fetchActivities = async () => {
  
    try {
      dispatch({
        type: "SET_LOADING",
      })
  
      const response = await axios.get("/admin/game/activities");
  
      const data = response?.data?.data

  
      dispatch({
        type: "SET_ALL_ACTIVITIES",
        payload: data,
      })

  
      setLoadingToFalse()
    } catch (error) {
      console.log({error})
      setLoadingToFalse();
    }
  }



  // Fetch Single Games
  const fetchSingleGames = async (gameId) => {
    dispatch({
      type: "SET_SINGLE_GAME_LOADING",
    });

    try {
   
      const response = await axios.get(`/admin/game/${gameId}`);
  
      const data = response?.data?.data

      // console.log("this is ther",response?.data)
  
      dispatch({
        type: "SET_SINGLE_GAME",
        payload: data,
      })
 
  
      setLoadingToFalse()
    } catch (error) {
      setLoadingToFalse();
    }
  };


  const fetchLiveSessions = async () => {
    dispatch({
      type: "SET_LIVE_SESSIONS_LOADING",
    })


      try {
        dispatch({
          type: "SET_LOADING",
        })
    
        const response = await axios.get("/admin/game/live-sessions");
        const data = response?.data?.data

     
    
    
        dispatch({
          type: "SET_LIVE_SESSIONS",
          payload: data,
        })
    
        setLoadingToFalse()
      } catch (error) {
        setLoadingToFalse();
      }
  }

  const fetchSingleGameLiveSession = async (gameId) => {
    dispatch({
      type: "SET_LIVE_SESSIONS_LOADING",
    })


    try {
      dispatch({
        type: "SET_LOADING",
      })
  
      const response = await axios.get(`{/admin/game/live-sessions/${gameId}}`);
      const data = response?.data?.data

    
  
  
      dispatch({
        type: "SET_LIVE_SESSIONS",
        payload: data,
      })
  
      setLoadingToFalse()
    } catch (error) {
      setLoadingToFalse();
    }
  }



  
  // // approve project
  // const approveProject = async (GamesId) => {
    
  //   dispatch({
  //     type: "SET_APPROVE_LOADING",
  //   })

  //   const response = await axios
  //     .post(`/admin/Games/${GamesId}`)
  //     .then((res) => {
  //       return true
  //     })
  //     .catch((err) => {
  //       return false
  //     })
  //     .finally(() => {
  //       // setLoadingToFalse()
  //     })

  //   return response
  // }


  
  
  // // decline project
  // const declineProject = async (GamesId) => {
    
  //   dispatch({
  //     type: "SET_APPROVE_LOADING",
  //   })

  //   const response = await axios
  //     .post(`/admin/Games/${GamesId}`)
  //     .then((res) => {
  //       return true
  //     })
  //     .catch((err) => {
  //       return false
  //     })
  //     .finally(() => {
  //       // setLoadingToFalse()
  //     })

  //   return response
  // }




  return (
    <GameContext.Provider
      value={{
        loading: state.loading,
        allGames: state.allGames,
        liveSessions: state.liveSessions,
        liveSessionsLoading: state.liveSessionsLoading,
        singleGameLoading: state.singleGameLoading,
        gameStats: state.gameStats,

        singleGameData: state.singleGameData,


        // approveProject,
        // declineProject,
        fetchAllGames,
        fetchLiveSessions,
        fetchSingleGameLiveSession,

        fetchSingleGames ,
        fetchActivities,
        allActivities: state.allActivities,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

export default GameContext
